import { useEffect, useState } from 'react';

interface RXTrackingPixelProps {
  seoPageId: number;
}

export function getRXTrackingUrl(seoPageId: number) {
  const { searchParams, pathname } = new URL(globalThis.location.href);
  searchParams.append('t_seoPageId', seoPageId.toString());
  searchParams.append('t_url', pathname);
  if (document.referrer) searchParams.append('t_referer', document.referrer);
  return `/track/clickAccessLog.do?${searchParams.toString()}`;
}

function RXTrackingPixel({ seoPageId }: RXTrackingPixelProps) {
  const [RXTrackingURL, setRXTrackingURL] = useState<string>();
  useEffect(() => {
    setRXTrackingURL(getRXTrackingUrl(seoPageId));
  }, [seoPageId]);

  if (!RXTrackingURL) {
    return null;
  }
  // eslint-disable-next-line @next/next/no-img-element
  return <img src={RXTrackingURL} width={1} height={1} alt="" style={{ display: 'none' }} />;
}

export default RXTrackingPixel;
