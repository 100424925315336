import { SourceLocation } from 'graphql';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  ServerError,
  ServerParseError,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import getConfig from 'next/config';
import fetch from 'cross-fetch';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { InfoData, ErrorData } from '@/types/common';

const dev = process.env.NODE_ENV !== 'test';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

interface LogError {
  (errorData: ErrorData): void;
}

interface LogInfo {
  (infoData: InfoData): void;
}

interface ApolloOptions {
  logError: LogError;
  logInfo: LogInfo;
}

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

function createApolloClient(options: ApolloOptions) {
  const logError = options.logError ? options.logError : console.error; // eslint-disable-line no-console
  const logInfo = options.logInfo ? options.logInfo : console.log; // eslint-disable-line no-console
  const devGraphqlUrl = serverRuntimeConfig.GRAPHQL_URL
    ? serverRuntimeConfig.GRAPHQL_URL
    : publicRuntimeConfig.GRAPHQL_URL;
  const graphqlUrl = dev ? devGraphqlUrl : 'https://www.stg.carezen.net/api/graphql';

  const httpLink = new HttpLink({
    uri: graphqlUrl,
    fetch,
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          searchResults: offsetLimitPagination(),
        },
      },
    },
  });

  const getLocationsString = (locations: readonly SourceLocation[] | undefined) => {
    let locationString = '';

    if (locations) {
      locationString = locations
        .map(({ column, line }) => `Line ${line} - Column ${column}`)
        .join(' | ');
    }

    return locationString;
  };

  const getPathString = (pathArray: readonly (string | number)[] | undefined) => {
    let pathString = '';
    if (pathArray) {
      pathString = pathArray.map((path) => `${path}`).join(' | ');
    }
    return pathString;
  };

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const elapsed = Math.floor(Date.now() - operation.getContext().start);

    const gqlPayload = {
      operationName: operation.operationName,
      variables: operation.variables,
      elapsed,
    };

    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path, extensions = {} }) => {
        const pathString = getPathString(path);
        const locationString = getLocationsString(locations);
        const errorMsg = `[GraphQL error]: Message: ${message}, ${
          locationString ? `Location: ${locationString},` : ''
        } ${pathString ? `Path: ${pathString}` : ''}`;
        const errorData = {
          errorMsg,
          path: pathString,
          payload: gqlPayload,
          serverError: {
            ...extensions,
          },
        };

        return logError({ msg: 'GraphQL error', ...errorData });
      });
    }

    if (networkError) {
      const { name, message, stack } = networkError;
      const { result, statusCode } = networkError as Partial<ServerError>;
      const { bodyText } = networkError as Partial<ServerParseError>;

      const errorData = {
        errorMsg: `[Network error]: ${message}`,
        payload: gqlPayload,
        type: 'GraphQL Network error',
        serverError: {
          name,
          message,
          stack,
          bodyText,
          statusCode,
          ...(result as {}),
        },
      };
      logError(errorData);
    }
  });

  const roundTripLink = new ApolloLink((operation, forward) => {
    // Called before operation is sent to server
    operation.setContext({ start: Date.now() });

    return forward(operation).map((data) => {
      // Called after server responds
      const time = Date.now() - operation.getContext().start;
      const infoData = {
        operationName: operation.operationName,
        duration: time,
        type: 'GraphQL info',
      };

      logInfo({
        infoMsg: '[GraphQL info]: Operation Round Trip',
        ...infoData,
      });

      return data;
    });
  });

  const link = ApolloLink.from([roundTripLink, errorLink, httpLink]);

  return new ApolloClient({
    name: publicRuntimeConfig.APP_NAME,
    version: publicRuntimeConfig.APP_VERSION,
    ssrMode: typeof globalThis.window === 'undefined',
    link,
    cache,
  });
}

export function getApolloClient(options: ApolloOptions) {
  /// create a new client if there's no existing one
  // or if we are running on the server.
  if (!apolloClient || typeof globalThis.window === 'undefined') {
    apolloClient = createApolloClient(options);
  }

  return apolloClient;
}
